<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col sm="12" md="12" lg="12" xl="12">
				<filters @selectedFilter="applyFiltersEvent($event)"></filters>
			</b-col>
		</b-row>
		<b-row class="match-height">
			<b-col lg="12">
				<b-row class="match-height">
					<b-col sm="12" md="12" lg="12" xl="12">
						<b-card no-body class="mb-0">
							<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

								<b-card-header>
									<b-card-title><span v-if="siteObject">Site: {{ siteObject.siteURL }}</span></b-card-title>
								</b-card-header>

								<b-table ref="refUserListTable" class="position-relative p-1" :items="tableSiteListArray" responsive :fields="tableColumnsArray" primary-key="id" show-empty empty-text="No records found">

									<template #cell(daterange)="data">
										<feather-icon v-if="!data.item.daterange.date_type" icon="LoaderIcon" size="18" class="spinner" />

										<span v-if="data.item.daterange.date_type.id != 'custom_date'">
											{{ data.item.daterange.date_type.text }}
										</span>
										
										<span v-if="data.item.daterange.date_type.id == 'custom_date'">
											{{ data.item.daterange.date_type.text }} ({{ data.item.daterange.custom_start_date }} - {{ data.item.daterange.custom_end_date }})
										</span>

									</template>

									<template #cell(total_views)="data">
										<feather-icon v-if="data.item.total_views == ''" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_views != ''">{{ data.item.total_views }}</span>
									</template>

									<template #cell(total_emails)="data">
										<feather-icon v-if="data.item.total_emails == ''" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_emails != ''">{{ data.item.total_emails }}</span>
									</template>

									<template #cell(total_phones)="data">
										<feather-icon v-if="data.item.total_phones == ''" icon="LoaderIcon" size="18" class="spinner" />
										<span v-if="data.item.total_phones != ''">{{ data.item.total_phones }}</span>
									</template>

								</b-table>

							</b-overlay>
						</b-card>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</section>
</template>

<script>
	import dateTypeListOptions from "@/libs/dateTypeListOptions";
	import {
		BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BButton, BTable
	} from "bootstrap-vue";

	import Filters from "./Filter.vue";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BButton, BTable,
			Filters,
		},
		data() {
			return {
				isFetchingRecordsFromServer: false,
				statisticsFilterData: {},
				tableColumnsArray: [
					{ key: 'daterange', label: 'Date' },
					{ key: 'total_views', label: 'Views' },
					{ key: 'total_emails', label: 'Emails Collected' },
					{ key: 'total_phones', label: 'Phone Collected' },
				],
				tableSiteListArray: [],
				selectedFilters: {},
				siteObject: {},
			};
		},
		methods: {
			applyFiltersEvent(filterObject) {

				/* RESET LODE MORE RECORDS : START */
				this.tableSiteListArray = [];
				/* RESET LODE MORE RECORDS : END */

				this.selectedFilters = filterObject; /* ADD FILERTS TO OBJECT */
				this.siteObject = filterObject.site;

				let dateTypeObject = _.keyBy(this.dateTypeOptions, 'id');

				let dateType_custom = filterObject.date_type_custom_filter ? JSON.parse(filterObject.date_type_custom_filter) : {};
				this.tableSiteListArray.push({
					is_compare_on: 0,
					daterange: {
						date_type: dateTypeObject[filterObject.date_type],
						custom_start_date: dateType_custom.start ?? '',
						custom_end_date: dateType_custom.end ?? '',
					},
					total_views: '',
					total_emails: '',
					total_phones: '',
				});

				if (filterObject.is_compare_on == 1) {
					let dateType_custom_compare = filterObject.compare_date_type_custom_filter ? JSON.parse(filterObject.compare_date_type_custom_filter) : {};
					this.tableSiteListArray.push({
						is_compare_on: 1,
						daterange: {
							date_type: dateTypeObject[filterObject.compare_date_type],
							custom_start_date: dateType_custom_compare.start ?? '',
							custom_end_date: dateType_custom_compare.end ?? '',
						},
						total_views: '',
						total_emails: '',
						total_phones: '',
					});
				}

				for (const selectedFilter of this.tableSiteListArray) {
					this.fetchSiteConversions(selectedFilter);
				}
				/* SET ALL SITES TO SITE ARRAY AND LOAD FIRST 10 RECORDS : END */

			},
			fetchSiteConversions(selectedFilter) {
				this.isFetchingRecordsFromServer = true;
				this.$reportAPI.post('fetch-site-comparison', {
					'auth_id': this.$root.authUserObject.id ?? '',
					'site_id': this.siteObject.siteId ?? '',
					'is_compare_on': selectedFilter.is_compare_on ?? '',
					'date_type': selectedFilter.daterange.date_type.id ?? '',
					'custom_start_date': selectedFilter.daterange.custom_start_date ?? '',
					'custom_end_date': selectedFilter.daterange.custom_end_date ?? '',
				}).then((response) => {
					response = response.data;
					if (response.status) {
						// console.log(response.compare_report); console.table();
						this.tableSiteListArray = _.map(this.tableSiteListArray, (obj) => {
							if (obj.is_compare_on == response.compare_report.is_compare_on) {
								obj.total_views = response.compare_report.total_visitor;
								obj.total_emails = response.compare_report.total_email;
								obj.total_phones = response.compare_report.total_phone;
							}
							return obj;
						});
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
					this.isFetchingRecordsFromServer = false;
				});
			}
		},
		setup() {

			const {
				dateTypeOptions,
			} = dateTypeListOptions()

			return {
				dateTypeOptions
			}
		},
	};
</script>